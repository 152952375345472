export type SupportedLanguage = 'jp' | 'us' | 'kr';

export const BASEURL: Record<string, { appUrl: string | undefined }> = {
  jp: { appUrl: process.env.NEXT_PUBLIC_APP_HOST_JA },
  kr: { appUrl: process.env.NEXT_PUBLIC_APP_HOST_KO },
  us: { appUrl: process.env.NEXT_PUBLIC_APP_HOST_EN },
};

export const PUSHER: Record<
  SupportedLanguage,
  { key: string; cluster: string }
> = {
  jp: {
    key: process.env.NEXT_PUBLIC_PUSHER_APP_KEY || '',
    cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER || '',
  },
  us: {
    key: process.env.NEXT_PUBLIC_PUSHER_APP_KEY_US || '',
    cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER_US || '',
  },
  kr: {
    key: process.env.NEXT_PUBLIC_PUSHER_APP_KEY_KR || '',
    cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER_KR || '',
  },
};
